export const endDate = (activeAt, duration) => {
    const activationDate = new Date(activeAt);
    const currentDate = new Date(); // Текущая дата и время
    // Проверяем, истекло ли время
    if (currentDate > new Date(activationDate.getTime() + duration * 24 * 60 * 60 * 1000)) {
        return false;
    }
    // Вычисляем конечную дату
    const endDate = new Date(activationDate.getTime() + duration * 24 * 60 * 60 * 1000);
    // Форматируем дату
    const formattedEndDate = `${pad(endDate.getDate())}.${pad(endDate.getMonth() + 1)}.${endDate.getFullYear()} ${pad(endDate.getHours())}:${pad(endDate.getMinutes())}`;
    return formattedEndDate;
};
// Функция для форматирования числа в двузначную строку
function pad(number) {
    if (number < 10) {
        return '0' + number;
    }
    return number;
}
