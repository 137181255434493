export const balanceConverter = (balance) => {
    const strInput = balance.toString();
    const length = strInput.length;
    let result;
    if (length <= 2) {
        result = "0." + strInput.padStart(2, '0');
    }
    else {
        const integerPart = strInput.slice(0, length - 2);
        const decimalPart = strInput.slice(length - 2);
        result = integerPart + "." + decimalPart;
    }
    // Убираем лишние точки после десятичной части
    result = result.replace(/\.+/g, '.');
    // Если в дробной части одна цифра, добавляем ноль
    if (result.indexOf('.') !== -1 && result.split('.')[1].length === 1) {
        result += '0';
    }
    return result;
};
