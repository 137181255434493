// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Ms7X3qX_qU1XUChzaHFo{color:var(--tg-theme-hint-color);font-size:13px;letter-spacing:-0.078px;text-transform:uppercase;margin:35px 0 8px 0}`, "",{"version":3,"sources":["webpack://./src/pages/buy-esim/tariff/tariff.module.scss"],"names":[],"mappings":"AAAA,sBACE,gCAAA,CACA,cAAA,CACA,uBAAA,CACA,wBAAA,CACA,mBAAA","sourcesContent":[".title {\n  color: var(--tg-theme-hint-color);\n  font-size: 13px;\n  letter-spacing: -0.078px;\n  text-transform: uppercase;\n  margin: 35px 0 8px 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": `Ms7X3qX_qU1XUChzaHFo`
};
export default ___CSS_LOADER_EXPORT___;
