// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.HKjXkZvi9xjWZGcEkS4d{min-height:100vh}.z44dGUeUktGn88bF8MTr{margin-top:auto;margin-bottom:10px;text-align:center;color:var(--tg-theme-text-color)}`, "",{"version":3,"sources":["webpack://./src/pages/introduction/introduction.module.scss"],"names":[],"mappings":"AAAA,sBACE,gBAAA,CAGF,sBACE,eAAA,CACA,kBAAA,CACA,iBAAA,CACA,gCAAA","sourcesContent":[".wrapper {\n  min-height: 100vh;\n}\n\n.footer {\n  margin-top: auto;\n  margin-bottom: 10px;\n  text-align: center;\n  color: var(--tg-theme-text-color); \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `HKjXkZvi9xjWZGcEkS4d`,
	"footer": `z44dGUeUktGn88bF8MTr`
};
export default ___CSS_LOADER_EXPORT___;
