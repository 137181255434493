// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.RyCqmqihOE0gYpWKWHif{background:var(--tg-theme-button-color);color:var(--tg-theme-button-text-color);font-size:17px;font-weight:600;line-height:146%;text-align:center;padding:14px;width:100%;border-radius:12px}.RyCqmqihOE0gYpWKWHif:hover{background:#2d8ef7}`, "",{"version":3,"sources":["webpack://./src/components/ui/button/button.module.scss"],"names":[],"mappings":"AAAA,sBACE,uCAAA,CACA,uCAAA,CACA,cAAA,CACA,eAAA,CACA,gBAAA,CACA,iBAAA,CACA,YAAA,CACA,UAAA,CACA,kBAAA,CAGF,4BACE,kBAAA","sourcesContent":[".button {\n  background: var(--tg-theme-button-color);\n  color: var(--tg-theme-button-text-color);\n  font-size: 17px;\n  font-weight: 600;\n  line-height: 146%;\n  text-align: center;\n  padding: 14px;\n  width: 100%;\n  border-radius: 12px;\n}\n\n.button:hover {\n  background: #2d8ef7;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": `RyCqmqihOE0gYpWKWHif`
};
export default ___CSS_LOADER_EXPORT___;
