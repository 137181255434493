import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable import/no-extraneous-dependencies */
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import WebAppSDK from '@twa-dev/sdk';
import copy from 'copy-to-clipboard';
import { useTranslation } from 'react-i18next';
import { Alert, Div } from '@delab-team/de-ui';
import { smlAddr } from '../../../utils/smlAddr';
import { ROUTES } from '../../../utils/router';
import { SvgSelector } from '../../../assets/svg-selector';
import { useHapticFeedback } from '../../../hooks/useHapticFeedback';
import s from './info.module.scss';
export const Info = ({ rawAddress, totalBalance }) => {
    const [failedLoadAvatar, setFailedLoadAvatar] = useState(false);
    const [isCopiedAddress, setIsCopiedAddress] = useState(false);
    const navigate = useNavigate();
    const TgObj = WebAppSDK;
    const { t } = useTranslation();
    return (_jsxs("div", { className: s.inner, children: [isCopiedAddress && (_jsx(Alert, { type: "important", onClose: () => setIsCopiedAddress(false), icon: _jsx(_Fragment, { children: "\uD83D\uDCC3" }), autoCloseTimeout: 1000, position: "top-right", tgStyles: { background: 'var(--tg-theme-button-color)' }, className: s.alert, children: _jsx("span", { className: s.alertText, children: t('common.address-copied') }) })), _jsxs("div", { className: s.user, onClick: () => {
                    navigate(ROUTES.PROFILE);
                    useHapticFeedback();
                    TgObj.MainButton.hide();
                }, children: [_jsxs("div", { className: s.userInner, children: [_jsxs("div", { className: s.userAvatar, children: [_jsx("img", { src: `https://t.me/i/userpic/320/${TgObj?.initDataUnsafe?.user?.username}.jpg`, onLoad: (e) => {
                                            const target = e.currentTarget;
                                            if (target.naturalWidth === 1 || target.naturalHeight === 1) {
                                                target.style.display = 'none';
                                                setFailedLoadAvatar(true);
                                            }
                                        }, alt: "avatar" }), failedLoadAvatar && (_jsx("div", { className: s.userAvatarCustom, children: TgObj?.initDataUnsafe?.user?.username?.slice(0, 2) }))] }), _jsxs("div", { className: s.userInfo, children: [_jsxs("div", { className: s.userName, children: [_jsx(Div, { tgStyles: { color: 'var(--tg-theme-text-color)' }, children: TgObj?.initDataUnsafe?.user?.first_name }), _jsx(Div, { tgStyles: { color: 'var(--tg-theme-text-color)' }, children: TgObj?.initDataUnsafe?.user?.last_name })] }), rawAddress.length > 0 && (_jsx("div", { className: s.userAddress, onClick: () => {
                                            copy(rawAddress);
                                            setIsCopiedAddress(true);
                                            useHapticFeedback();
                                        }, children: smlAddr(rawAddress) }))] })] }), _jsx(SvgSelector, { id: "chevron-right" })] }), _jsxs("div", { className: s.balance, onClick: () => {
                    navigate(ROUTES.TOPUP);
                    useHapticFeedback();
                }, children: [_jsxs("div", { className: s.balanceLeft, children: [_jsx(SvgSelector, { id: "wallet" }), _jsxs("div", { className: s.balanceInfo, children: [_jsxs("div", { className: s.balanceWallet, children: ["$", (+totalBalance).toFixed(2)] }), _jsx("div", { className: s.balanceTitle, children: t('common.balance') })] })] }), _jsx("div", { className: s.balanceRight, children: _jsx("button", { className: s.balanceRightButton, children: _jsx(SvgSelector, { id: "add" }) }) })] })] }));
};
