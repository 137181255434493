// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.NWXNiaF9LUk_q4YrL0ay{color:var(--tg-theme-hint-color);font-size:13px;margin:35px 0 8px 0;padding-left:16px}.jn45RbmQMmv08p4WLFXp{font-size:16px;color:var(--tg-theme-hint-color);margin-bottom:8px}.XhCmE7v7gIQNiLdf_vFJ{margin-top:8px;border-radius:12px;position:relative;padding:16px 18px;display:flex;flex-direction:column;background:var(--tg-theme-bg-color)}.RNhbClFmpoDSbirvh_DO{display:flex;align-items:center;justify-content:center;gap:6px;margin-top:12px}.ewVzNV_vds7gwkC3ylc6{color:var(--tg-theme-hint-color);font-size:17px;font-weight:410}.HFgiGr6Y20e2YyRntSgn{color:var(--tg-theme-text-color);font-size:20px;font-weight:410;text-align:center}`, "",{"version":3,"sources":["webpack://./src/components/top-up/method/method.module.scss"],"names":[],"mappings":"AAAA,sBACI,gCAAA,CACA,cAAA,CACA,mBAAA,CACA,iBAAA,CAGJ,sBACI,cAAA,CACA,gCAAA,CACA,iBAAA,CAGJ,sBACI,cAAA,CACA,kBAAA,CACA,iBAAA,CACA,iBAAA,CACA,YAAA,CAEA,qBAAA,CACA,mCAAA,CAGJ,sBACI,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,OAAA,CACA,eAAA,CAGJ,sBACI,gCAAA,CACA,cAAA,CACA,eAAA,CAGJ,sBACI,gCAAA,CACA,cAAA,CACA,eAAA,CACA,iBAAA","sourcesContent":[".title {\n    color: var(--tg-theme-hint-color);\n    font-size: 13px;\n    margin: 35px 0 8px 0;\n    padding-left: 16px;\n}\n\n.titleTwo {\n    font-size: 16px;\n    color: var(--tg-theme-hint-color);\n    margin-bottom: 8px;\n}\n\n.inner {\n    margin-top: 8px;\n    border-radius: 12px;\n    position: relative;\n    padding: 16px 18px;\n    display: flex;\n\n    flex-direction: column;\n    background: var(--tg-theme-bg-color);\n}\n\n.receive {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    gap: 6px;\n    margin-top: 12px;\n}\n\n.receiveTitle {\n    color: var(--tg-theme-hint-color);\n    font-size: 17px;\n    font-weight: 410;\n}\n\n.receiveAmount {\n    color: var(--tg-theme-text-color);\n    font-size: 20px;\n    font-weight: 410;\n    text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": `NWXNiaF9LUk_q4YrL0ay`,
	"titleTwo": `jn45RbmQMmv08p4WLFXp`,
	"inner": `XhCmE7v7gIQNiLdf_vFJ`,
	"receive": `RNhbClFmpoDSbirvh_DO`,
	"receiveTitle": `ewVzNV_vds7gwkC3ylc6`,
	"receiveAmount": `HFgiGr6Y20e2YyRntSgn`
};
export default ___CSS_LOADER_EXPORT___;
