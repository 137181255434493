import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable max-len */
import { useState } from 'react';
export const CircleUsage = ({ percentage, text }) => {
    const chartRadius = 100;
    const strokeWidth = 30;
    const totalPercentage = 100;
    const calculateCircumference = () => 2 * Math.PI * (chartRadius - strokeWidth / 2);
    const offset = calculateCircumference() * ((totalPercentage - percentage) / totalPercentage);
    const [hoveredPercentage, setHoveredPercentage] = useState(null);
    const handleMouseOver = () => {
        setHoveredPercentage(percentage);
    };
    const handleMouseOut = () => {
        setHoveredPercentage(null);
    };
    const handleTouchStart = () => {
        setHoveredPercentage(percentage);
    };
    const handleTouchEnd = () => {
        setHoveredPercentage(null);
    };
    return (_jsxs("svg", { width: chartRadius * 2, height: chartRadius * 2, onMouseOver: handleMouseOver, onMouseOut: handleMouseOut, onTouchStart: handleTouchStart, onTouchEnd: handleTouchEnd, xmlns: "http://www.w3.org/2000/svg", xmlnsXlink: "http://www.w3.org/1999/xlink", children: [_jsx("circle", { cx: `${chartRadius}`, cy: `${chartRadius}`, r: `${chartRadius - strokeWidth / 2}`, fill: "transparent", stroke: "var(--tg-theme-button-color)", strokeWidth: strokeWidth, strokeDasharray: `${calculateCircumference()}, ${calculateCircumference()}`, strokeDashoffset: offset, transform: `rotate(-90, ${chartRadius}, ${chartRadius})`, vectorEffect: "non-scaling-stroke", onTouchStart: handleTouchStart, onTouchEnd: handleTouchEnd }), _jsx("circle", { cx: `${chartRadius}`, cy: `${chartRadius}`, r: `${chartRadius - strokeWidth / 2}`, fill: "transparent", stroke: "var(--tg-theme-secondary-bg-color)", strokeWidth: strokeWidth, strokeDasharray: `${calculateCircumference()}, ${calculateCircumference()}`, strokeDashoffset: offset - calculateCircumference(), vectorEffect: "non-scaling-stroke", transform: `rotate(-90, ${chartRadius}, ${chartRadius})`, onTouchStart: handleTouchStart, onTouchEnd: handleTouchEnd }), _jsx("text", { onTouchStart: handleTouchStart, onTouchEnd: handleTouchEnd, style: { userSelect: 'none' }, x: chartRadius, y: chartRadius, textAnchor: "middle", dominantBaseline: "middle", fill: "var(--tg-theme-text-color)", fontSize: "18", children: hoveredPercentage !== null ? hoveredPercentage + '%' : text })] }));
};
