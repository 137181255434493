// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.s2s780wCXwQJvWqhjmCg{display:flex;align-items:center;justify-content:center;flex-direction:column;height:100vh}.I8xU0TBRaQD0XLStkWBA{display:flex;align-items:center;justify-content:center;margin-bottom:10px}.mBFDNg6f2ViswbcEWex3{font-size:35px;font-weight:760;line-height:120%;max-width:250px;margin:10px 0;text-align:center;color:var(--tg-theme-text-color)}.ykkTmkubbk5dhxBTNVZT{color:#3495ff}.e3pTM9DSrD2sFpxNugne{font-size:18px;margin-bottom:12px;color:var(--tg-theme-text-color);text-align:center}`, "",{"version":3,"sources":["webpack://./src/pages/something-wrong/something-wrong.module.scss"],"names":[],"mappings":"AAAA,sBACI,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,qBAAA,CACA,YAAA,CACA,sBACI,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,kBAAA,CAEJ,sBACI,cAAA,CACA,eAAA,CACA,gBAAA,CACA,eAAA,CACA,aAAA,CACA,iBAAA,CACA,gCAAA,CACA,sBACI,aAAA,CAGR,sBACI,cAAA,CACA,kBAAA,CACA,gCAAA,CACA,iBAAA","sourcesContent":[".inner {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    flex-direction: column;\n    height: 100vh;\n    &Img {\n        display: flex;\n        align-items: center;\n        justify-content: center;\n        margin-bottom: 10px;\n    }\n    &Title {\n        font-size: 35px;\n        font-weight: 760;\n        line-height: 120%;\n        max-width: 250px;\n        margin: 10px 0;\n        text-align: center;\n        color: var(--tg-theme-text-color);\n        &Red {\n            color: #3495ff;\n        }\n    }\n    &Text {\n        font-size: 18px;\n        margin-bottom: 12px;\n        color: var(--tg-theme-text-color);\n        text-align: center;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"inner": `s2s780wCXwQJvWqhjmCg`,
	"innerImg": `I8xU0TBRaQD0XLStkWBA`,
	"innerTitle": `mBFDNg6f2ViswbcEWex3`,
	"innerTitleRed": `ykkTmkubbk5dhxBTNVZT`,
	"innerText": `e3pTM9DSrD2sFpxNugne`
};
export default ___CSS_LOADER_EXPORT___;
