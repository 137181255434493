// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.lWQExrHht26LchIHKcGr{display:flex;align-items:flex-start;gap:13px;margin:35px 0}.lWQExrHht26LchIHKcGr svg{margin-top:3px;min-width:22px;min-height:22px}.kRE2i2tnbhHmOEuDEwvF{display:block;color:var(--tg-theme-text-color);font-size:17px;letter-spacing:-0.43px}.ASl4YmTbyzagTMvdzWH6{color:var(--tg-theme-hint-color);font-size:13px;letter-spacing:-0.078px;text-transform:uppercase;padding-left:16px;margin-bottom:8px}`, "",{"version":3,"sources":["webpack://./src/pages/buy-esim/confirm/confirm.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,sBAAA,CACA,QAAA,CACA,aAAA,CACA,0BACE,cAAA,CACA,cAAA,CACA,eAAA,CAEF,sBACE,aAAA,CACA,gCAAA,CACA,cAAA,CACA,sBAAA,CAIJ,sBACE,gCAAA,CACA,cAAA,CACA,uBAAA,CACA,wBAAA,CACA,iBAAA,CACA,iBAAA","sourcesContent":[".check {\n  display: flex;\n  align-items: flex-start;\n  gap: 13px;\n  margin: 35px 0;\n  svg {\n    margin-top: 3px;\n    min-width: 22px;\n    min-height: 22px;\n  }\n  &Title {\n    display: block;\n    color: var(--tg-theme-text-color);\n    font-size: 17px;\n    letter-spacing: -0.43px;\n  }\n}\n\n.title {\n  color: var(--tg-theme-hint-color);\n  font-size: 13px;\n  letter-spacing: -0.078px;\n  text-transform: uppercase;\n  padding-left: 16px;\n  margin-bottom: 8px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"check": `lWQExrHht26LchIHKcGr`,
	"checkTitle": `kRE2i2tnbhHmOEuDEwvF`,
	"title": `ASl4YmTbyzagTMvdzWH6`
};
export default ___CSS_LOADER_EXPORT___;
