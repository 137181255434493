import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Button } from '@delab-team/de-ui';
import { useTranslation } from 'react-i18next';
import s from './amount.module.scss';
import { SvgSelector } from '../../../assets/svg-selector';
import { useHapticFeedback } from '../../../hooks/useHapticFeedback';
export const Amount = ({ topUpData, setTopUpData }) => {
    const increaseAmount = (increment) => {
        setTopUpData((prevData) => {
            const currentAmount = parseFloat(prevData?.amount || '0');
            const newAmount = (currentAmount + increment).toFixed(2);
            return {
                ...prevData,
                amount: newAmount.replace(',', '.')
            };
        });
    };
    const { t } = useTranslation();
    return (_jsx(_Fragment, { children: _jsxs("div", { className: s.inner, children: [_jsxs("div", { className: s.inputInner, children: [_jsx(SvgSelector, { id: "usd" }), _jsx("input", { className: s.input, value: topUpData?.amount.replace(',', '.') || '', onKeyDown: evt => ['e', 'E', '+', '-'].includes(evt.key) && evt.preventDefault(), onChange: (e) => {
                                if (parseFloat(e.target.value) > 10000) {
                                    return;
                                }
                                if (e.target.value === '0'
                                    || e.target.value === ','
                                    || e.target.value === '.') {
                                    return;
                                }
                                // @ts-ignore
                                if (e.target.value.indexOf('.') != '-1') {
                                    e.target.value = e.target.value.substring(0, e.target.value.indexOf('.') + 3);
                                }
                                if (isNaN(Number(e.target.value))) {
                                    return;
                                }
                                setTopUpData({
                                    ...topUpData,
                                    amount: e.target.value.replace(',', '.')
                                });
                            }, pattern: "[0-9]+([\\.][0-9]{1,2})?", placeholder: t('top-up.step-1.amount-to-top'), inputMode: "numeric", type: "text" }), topUpData?.amount.length >= 1 && (_jsx("button", { className: s.clearInput, onClick: () => {
                                setTopUpData({
                                    ...topUpData,
                                    amount: ''
                                });
                                useHapticFeedback();
                            }, children: _jsx(SvgSelector, { id: "clear" }) }))] }), _jsxs("div", { className: s.actions, children: [_jsx(Button, { className: s.btn, onClick: () => {
                                increaseAmount(10);
                                useHapticFeedback();
                            }, children: "+$10" }), _jsx(Button, { className: s.btn, onClick: () => {
                                increaseAmount(20);
                                useHapticFeedback();
                            }, children: "+$20" }), _jsx(Button, { className: s.btn, onClick: () => {
                                increaseAmount(30);
                                useHapticFeedback();
                            }, children: "+$30" })] })] }) }));
};
