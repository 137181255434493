import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable prefer-const */
/* eslint-disable consistent-return */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Title } from '@delab-team/de-ui';
import WebAppSDK from '@twa-dev/sdk';
import { useTranslation } from 'react-i18next';
import { Address, toNano } from '@ton/core';
import TonWeb from 'tonweb';
import { useTonAddress, useTonConnectUI } from '@tonconnect/ui-react';
import { Amount } from './amount';
import { Method } from './method';
import { Status } from '../status';
import { SvgSelector } from '../../assets/svg-selector';
import { resolveJettonAddressFor, sendJettonToBoc } from '../../utils/sendJetton';
import s from './top-up.module.scss';
import { useHapticFeedback } from '../../hooks/useHapticFeedback';
const NEXT_STEP_COLOR = '#40a7e3';
const DISABLED_BUTTON_COLOR = '#78B5F9';
const ERROR_TEXT_COLOR = '#FF0026';
export const TopUp = ({ handleIntroductionClose, showIntroduction, userId, mongoId }) => {
    const TgObj = WebAppSDK;
    const [tonConnectUI, setOptions] = useTonConnectUI();
    const [activeStep, setActiveStep] = useState(0);
    const [topUpData, setTopUpData] = useState({
        token: '',
        amount: '',
        tokenAddress: '',
        transactionAmount: ''
    });
    const [isSuccess, setIsSuccess] = useState(true);
    let rawAddress = useTonAddress();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const handlePrevStep = () => {
        if (activeStep === 0) {
            navigate('/');
            useHapticFeedback();
            return;
        }
        setActiveStep(prevStep => prevStep - 1);
        useHapticFeedback();
    };
    const createTransaction = async (currency, amount) => {
        const addressSIM = 'UQDILBLIPwj7cFA0SjEzOXdqZ7m-VpfsbAjilWJs_wWkuwjM';
        const TRANSACTION_FEE = 0.18;
        const VALIDITY_DURATION = 60;
        try {
            TgObj.MainButton.showProgress();
            TgObj.MainButton.disable();
            let payload;
            if (currency === 'TON') {
                const cell = new TonWeb.boc.Cell();
                cell.bits.writeUint(0, 32);
                cell.bits.writeString(mongoId);
                payload = TonWeb.utils.bytesToBase64(await cell.toBoc());
            }
            else {
                payload = sendJettonToBoc({
                    to: addressSIM,
                    amount: toNano(amount).toString(),
                    comment: mongoId
                }, addressSIM);
            }
            let address;
            if (currency !== 'TON') {
                const jettonWallet = await resolveJettonAddressFor(Address.parse(currency).toString(), Address.parse(rawAddress).toString());
                if (!jettonWallet) {
                    console.error('No jetton wallet address');
                    return false;
                }
                address = Address.parse(jettonWallet).toString();
            }
            const messages = [
                {
                    address: currency === 'TON' ? addressSIM : address,
                    amount: toNano(currency === 'TON' ? amount : TRANSACTION_FEE).toString(),
                    payload
                }
            ].filter(Boolean);
            const tr = {
                validUntil: Math.floor(Date.now() / 1000) + VALIDITY_DURATION,
                messages
            };
            await tonConnectUI.sendTransaction(tr);
            return true;
        }
        catch (error) {
            console.error('Transaction error:', error);
            return false;
        }
        finally {
            TgObj.MainButton.hideProgress();
            TgObj.MainButton.enable();
        }
    };
    const handleNextStep = () => {
        if (activeStep === 1) {
            if (!rawAddress) {
                tonConnectUI.connectWallet().then((res) => {
                    rawAddress = res.account.address;
                });
                return;
            }
            createTransaction(topUpData.token === 'TON' ? 'TON' : topUpData.tokenAddress, Number(topUpData.transactionAmount))
                .then((res) => {
                if (!res) {
                }
                else {
                    setActiveStep(prev => prev + 1);
                }
            })
                .catch((err) => {
                TgObj.showAlert('Произошла ошибка при платеже');
            });
            return;
        }
        if (activeStep === 2) {
            isSuccess ? handleIntroductionClose() : handlePrevStep();
        }
        else {
            setActiveStep(prevStep => prevStep + 1);
        }
    };
    const enableBtn = () => {
        TgObj.MainButton.show();
        if (activeStep === 0) {
            TgObj.BackButton.show();
            if (!topUpData.amount || topUpData.amount === '0') {
                TgObj.MainButton.disable();
                TgObj.MainButton.color = DISABLED_BUTTON_COLOR;
                TgObj.MainButton.text = t('common.next-step');
            }
            else {
                TgObj.MainButton.enable();
                TgObj.MainButton.color = NEXT_STEP_COLOR;
                TgObj.MainButton.text = t('common.next-step');
            }
        }
        else if (activeStep === 1) {
            if (!topUpData.token?.length) {
                TgObj.MainButton.disable();
                TgObj.MainButton.color = DISABLED_BUTTON_COLOR;
                TgObj.MainButton.text = t('common.next-step');
            }
            else {
                TgObj.MainButton.enable();
                TgObj.MainButton.color = NEXT_STEP_COLOR;
                TgObj.MainButton.text = t('common.next-step');
            }
        }
        else if (activeStep === 2) {
            if (!isSuccess) {
                TgObj.MainButton.enable();
                TgObj.MainButton.color = NEXT_STEP_COLOR;
                TgObj.MainButton.text = t('common.next-step');
            }
            else {
                TgObj.MainButton.enable();
                TgObj.MainButton.color = NEXT_STEP_COLOR;
                TgObj.MainButton.text = t('common.return-to-main');
            }
        }
        else {
            TgObj.MainButton.hide();
        }
    };
    const backBtn = () => {
        TgObj.BackButton.show();
    };
    useEffect(() => {
        enableBtn();
        backBtn();
        TgObj.MainButton.onClick(handleNextStep);
        if (activeStep !== 0) {
            backBtn();
            TgObj.BackButton.onClick(handlePrevStep);
        }
        else if (!showIntroduction && activeStep === 0) {
            backBtn();
            TgObj.BackButton.onClick(handlePrevStep);
        }
        return () => {
            TgObj.MainButton.offClick(handleNextStep);
            TgObj.BackButton.offClick(handlePrevStep);
        };
    }, [handleNextStep, activeStep]);
    const step = activeStep === 0
        ? t('top-up.steps.amount')
        : activeStep === 1
            ? t('top-up.steps.method')
            : activeStep === 2
                ? t('top-up.steps.confirm')
                : t('top-up.steps.unknown');
    return (_jsxs(_Fragment, { children: [_jsx(Title, { variant: "h2", className: s.title, children: t('top-up.title') }), _jsxs("div", { className: s.top, children: [_jsxs("div", { className: s.topInfo, children: [t('common.step'), " ", activeStep + 1, ": ", step] }), _jsxs("div", { className: s.topInner, children: [_jsx("div", { className: `${s.step} ${s.stepActive} ${activeStep >= 1 ? s.stepChecked : ''}`, children: activeStep >= 1 && _jsx(SvgSelector, { id: "check2" }) }), _jsx("div", { className: s.divider, style: { background: activeStep >= 1 ? NEXT_STEP_COLOR : '' } }), _jsx("div", { className: `${s.step} ${activeStep >= 1 ? s.stepActive : ''} ${activeStep >= 2 ? s.stepChecked : ''}`, children: activeStep >= 2 && _jsx(SvgSelector, { id: "check2" }) }), _jsx("div", { className: s.divider, style: { background: activeStep >= 2 ? NEXT_STEP_COLOR : '' } }), _jsx("div", { className: `${s.step} ${activeStep >= 2 ? s.stepActive : ''} ${!isSuccess ? s.stepError : ''}`, style: { color: !isSuccess ? ERROR_TEXT_COLOR : '' } })] })] }), activeStep === 0 && _jsx(Amount, { topUpData: topUpData, setTopUpData: setTopUpData }), activeStep === 1 && _jsx(Method, { topUpData: topUpData, setTopUpData: setTopUpData }), activeStep === 2 && _jsx(Status, { isSuccess: isSuccess })] }));
};
