import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable operator-linebreak */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable import/extensions */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-prototype-builtins */
/* eslint-disable no-return-assign */
/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
import { useEffect, useState } from 'react';
import { Routes, Route, Navigate, useNavigate } from 'react-router-dom';
import { TonApi } from '@delab-team/ton-api-sdk';
import { AppInner } from '@delab-team/de-ui';
import { fromNano } from 'ton-core';
import { useTranslation } from 'react-i18next';
import WebAppSDK from '@twa-dev/sdk';
import { useTonAddress } from '@tonconnect/ui-react';
import { Layout } from './layout';
import { TopUp } from './components/top-up';
import { Home } from './pages/home';
import { Introduction } from './pages/introduction';
import { Profile } from './pages/profile';
import { BuyEsim } from './pages/buy-esim';
import { tokensData as tokensMocks } from './mocks/tokens';
import { ROUTES } from './utils/router';
import './utils/i18n';
import { getMe } from './api/auth';
import { SpinnerScreen } from './components/spinner-screen';
import { SomethingWentWrong } from './pages/something-wrong';
import globalRouter from './utils/globalRouter';
import { transactionCheck } from './api/transactions';
WebAppSDK.ready();
export const App = () => {
    const TgObj = WebAppSDK;
    const navigate = useNavigate();
    globalRouter.navigate = navigate;
    const [firstRender, setFirstRender] = useState(false);
    const [isTg, setIsTg] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    // introduction
    const [showIntroduction, setShowIntroduction] = useState(true);
    // Skipped introduction
    const [isSkippedIntroduction, setIsSkippedIntroduction] = useState(false);
    const [totalBalance, setTotalBalance] = useState('0.00');
    const [user, setUser] = useState(undefined);
    const RawAddress = useTonAddress();
    const api = new TonApi('AFJOFCU7XVPBKMIAAAAM6H4MQHHW57TAGBG2EY3C3A6USOZUSGXEI6MRDW32YKVQDZBCGUQ', 'mainnet');
    useEffect(() => {
        if (!user) {
            transactionCheck();
            getMe()
                .then((res) => {
                if (!res) {
                    navigate(ROUTES.SOMETHING_WENT_WRONG);
                    return;
                }
                if (!res.data) {
                    navigate(ROUTES.SOMETHING_WENT_WRONG);
                    return;
                }
                setUser({
                    id: res.data.tg_id,
                    balance: res.data.balance,
                    tg_id: res.data.tg_id,
                    orders: res.data.orders,
                    _id: res.data._id,
                    ...res.data
                });
            })
                .catch((err) => {
                navigate(ROUTES.SOMETHING_WENT_WRONG);
            })
                .finally(() => setIsLoading(false));
        }
    }, []);
    async function getJettons(address) {
        try {
            const res = await api.Accounts.getAllJettonsBalances(address, 'usd');
            const tokenAddressesArr = tokensMocks?.map(item => item?.tokenAddress[0]);
            const tonInfo = await api.Accounts.getHumanFriendlyInfo(address);
            const jettonAddresses = res?.balances?.map(el => el?.jetton?.address);
            jettonAddresses?.push('TON');
            const priceRes = await api?.Rates.getTokenPrice(jettonAddresses, 'usd');
            // @ts-ignore
            const tonPrice = priceRes?.rates.TON?.prices?.USD;
            const updatedTokens = tokensMocks?.map((el) => {
                if (el.token === 'TON') {
                    return {
                        ...el,
                        amount: fromNano(tonInfo.balance),
                        amountUSD: (tonPrice * Number(fromNano(tonInfo.balance))).toString()
                    };
                }
                const matchingAddresses = tokenAddressesArr?.filter(el3 => priceRes?.rates?.hasOwnProperty(el3));
                for (const matchingAddress of matchingAddresses ?? '') {
                    if (el.tokenAddress[0] === matchingAddress) {
                        // @ts-ignore
                        const matchingBalance = priceRes?.rates[matchingAddress]?.prices?.USD;
                        const tokenPrice = res?.balances.find(el4 => el4?.jetton?.address === matchingAddress);
                        return {
                            ...el,
                            amount: fromNano(tokenPrice.balance),
                            amountUSD: (parseFloat(fromNano(tokenPrice.balance).toString()) *
                                parseFloat(matchingBalance)).toFixed(2)
                        };
                    }
                }
                return el;
            });
            const additionalTokens = res?.balances
                ?.filter(el => el?.jetton?.address &&
                !tokenAddressesArr?.includes(el?.jetton.address ?? '') &&
                !updatedTokens?.some(updatedToken => updatedToken?.tokenAddress[0] === el?.jetton?.address))
                .map((el) => {
                // @ts-ignore
                const additionalTokenPrice = 
                // @ts-ignore
                priceRes?.rates[el?.jetton?.address ?? '']?.prices?.USD ?? '0';
                return {
                    token: el?.jetton?.name ?? '',
                    tokenLogo: el?.jetton?.image ?? '',
                    amount: fromNano(el.balance),
                    amountUSD: (parseFloat(fromNano(el?.balance).toString()) *
                        parseFloat(additionalTokenPrice)).toFixed(2),
                    tokenAddress: [el?.jetton?.address ?? '']
                };
            }) || [];
            const finalUpdatedTokens = [...updatedTokens, ...additionalTokens];
            setTotalBalance(finalUpdatedTokens
                .reduce((acc, el) => acc + parseFloat(el.amountUSD ?? '0'), 0)
                .toFixed(2));
        }
        catch (error) {
            console.error(error);
        }
    }
    useEffect(() => {
        if (!RawAddress || RawAddress === '') {
            return;
        }
        getJettons(RawAddress);
    }, [RawAddress]);
    // init twa
    useEffect(() => {
        if (!firstRender) {
            setFirstRender(true);
            const isTgCheck = window.Telegram.WebApp.initData !== '';
            const bodyStyle = document.body.style;
            if (isTgCheck) {
                TgObj.ready();
                TgObj.enableClosingConfirmation();
                TgObj.expand();
                setIsTg(true);
                bodyStyle.backgroundColor = 'var(--tg-theme-secondary-bg-color)';
                bodyStyle.setProperty('background-color', 'var(--tg-theme-secondary-bg-color)', 'important');
            }
        }
    }, []);
    useEffect(() => {
        const hasPassedIntroduction = localStorage.getItem('hasPassedIntroduction');
        if (hasPassedIntroduction) {
            setShowIntroduction(false);
        }
        else {
            navigate(ROUTES.INTRODUCTION);
        }
    }, []);
    useEffect(() => {
        const hasSkippedIntroduction = localStorage.getItem('hasPassedIntroduction');
        if (hasSkippedIntroduction) {
            setIsSkippedIntroduction(true);
        }
    }, []);
    const savedLanguage = localStorage.getItem('i18nextLng');
    const [selectedLanguage, setSelectedLanguage] = useState(savedLanguage || 'en');
    const { i18n } = useTranslation();
    useEffect(() => {
        const initializeLanguage = async () => {
            const TgLanguage = TgObj?.initDataUnsafe?.user?.language_code;
            let language;
            const userDefinedLanguage = localStorage.getItem('i18nextLngOwn');
            if (userDefinedLanguage) {
                language = userDefinedLanguage;
            }
            else if (TgLanguage) {
                const lowerCaseTgLanguage = TgLanguage.toLowerCase();
                if (lowerCaseTgLanguage === 'ru' || lowerCaseTgLanguage === 'en') {
                    language = lowerCaseTgLanguage;
                }
                else {
                    language = 'en';
                }
            }
            else {
                language = 'en';
            }
            setSelectedLanguage(language);
        };
        if (isTg && !savedLanguage) {
            initializeLanguage();
        }
    }, [isTg]);
    useEffect(() => {
        i18n.changeLanguage(selectedLanguage);
        localStorage.setItem('i18nextLng', selectedLanguage);
    }, [selectedLanguage, i18n]);
    return (_jsx(AppInner, { isTg: isTg, children: isLoading ? (_jsx(SpinnerScreen, {})) : (_jsx(Layout, { children: _jsxs(Routes, { children: [user && (_jsx(Route, { path: ROUTES.HOME, element: _jsx(Home, { rawAddress: RawAddress, totalBalance: user?.balance.toString(), esims: user?.orders?.filter(item => item.active) }) })), _jsx(Route, { path: ROUTES.INTRODUCTION, element: _jsx(Introduction, { isTg: isTg, rawAddress: RawAddress, setShowIntroduction: setShowIntroduction, showIntroduction: showIntroduction }) }), _jsx(Route, { path: ROUTES.TOPUP, element: _jsx(TopUp
                        // @ts-ignore
                        , { 
                            // @ts-ignore
                            userId: user?.id, 
                            // @ts-ignore
                            mongoId: user?._id, showIntroduction: showIntroduction, handleIntroductionClose: () => {
                                navigate('/');
                                window.location.reload();
                            } }) }), _jsx(Route, { path: ROUTES.PROFILE, element: _jsx(Profile, { rawAddress: RawAddress, selectedLanguage: selectedLanguage, setSelectedLanguage: setSelectedLanguage }) }), _jsx(Route, { path: ROUTES.SOMETHING_WENT_WRONG, element: _jsx(SomethingWentWrong, { isError: true }) }), _jsx(Route, { path: ROUTES.BUY_ESIM, element: _jsx(BuyEsim, {}) }), _jsx(Route, { path: "*", element: _jsx(Navigate, { to: "/", replace: true }) })] }) })) }));
};
