import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Title } from '@delab-team/de-ui';
import { Esim } from '../../../components/esim';
import { useHapticFeedback } from '../../../hooks/useHapticFeedback';
import { SvgSelector } from '../../../assets/svg-selector';
import { ROUTES } from '../../../utils/router';
import s from './esims.module.scss';
export const Esims = ({ setShowDetails, setEsimInfoDetail, esims }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    return (_jsxs("div", { className: s.inner, children: [_jsx(Title, { variant: "h2", className: s.title, children: t('home-page.title') }), esims.length > 0 ? (_jsx("ul", { className: s.esimsList, children: esims.map((esim) => (_jsx(Esim, { setShowDetails: setShowDetails, internetLimit: esim.volume, internetUsed: esim.volumeUsage, activeAt: esim.activeAt, rate: esim.packageName, setEsimInfoDetail: setEsimInfoDetail, activationCode: esim.smdpAddress, status: 'GOT_RESOURCE', countryCode: esim.locationCode, qrUrl: esim.qrCodeUrl, duration: esim.duration }, esim._id))) })) : (_jsxs("div", { className: s.create, onClick: () => {
                    navigate(ROUTES.BUY_ESIM);
                    useHapticFeedback();
                }, children: [_jsx(SvgSelector, { id: "create" }), _jsx("div", { className: s.createText, children: t('home-page.title') })] }))] }));
};
