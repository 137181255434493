// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.qKGuN9Hvuhqvya_hAQlp{width:100%}`, "",{"version":3,"sources":["webpack://./src/components/country-skeleton/country-skeleton.module.scss"],"names":[],"mappings":"AAAA,sBACI,UAAA","sourcesContent":[".loader {\n    width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loader": `qKGuN9Hvuhqvya_hAQlp`
};
export default ___CSS_LOADER_EXPORT___;
