import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { Title } from '@delab-team/de-ui';
import { CircleUsage } from '../../circle-usage';
import s from './internet-detail.module.scss';
import { formatBytes } from '../../../utils/sizeConvert';
export const InternetDetail = ({ internetTotal, internetUsed }) => {
    const { t } = useTranslation();
    return (_jsxs(_Fragment, { children: [_jsxs("div", { className: s.info, children: [_jsx(Title, { variant: "h2", className: s.infoTitle, children: t('common.internet-usage') }), _jsxs("div", { className: s.infoUsed, children: [formatBytes(internetUsed), "/", formatBytes(internetTotal)] })] }), _jsx("div", { className: s.chart, children: _jsx(CircleUsage, { percentage: Number(((internetUsed / internetTotal) * 100).toFixed(2)), text: `${formatBytes(internetUsed)}` }) })] }));
};
