import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { Title } from '@delab-team/de-ui';
import { useTranslation } from 'react-i18next';
import WebAppSDK from '@twa-dev/sdk';
import { TokenSelector } from '../../token-selector';
import s from './method.module.scss';
import { getAvailableJettons } from '../../../api/jettons';
import { TokenSelectorSkeleton } from '../../token-selector-skeleton';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../../utils/router';
export const Method = ({ topUpData, setTopUpData }) => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const TgObj = WebAppSDK;
    const [tokens, setTokens] = useState([]);
    const [isTokensLoading, setIsTokensLoading] = useState(true);
    useEffect(() => {
        getAvailableJettons()
            .then((res) => {
            setTokens(res.data);
        })
            .finally(() => setIsTokensLoading(false))
            .catch((err) => TgObj.showAlert('Error', () => {
            navigate(ROUTES.HOME);
        }));
    }, []);
    return (_jsxs(_Fragment, { children: [_jsxs("div", { className: s.inner, children: [_jsx(Title, { variant: "h3", className: s.titleTwo, children: t('common.send') }), isTokensLoading ? (_jsx(TokenSelectorSkeleton, {})) : (_jsx(TokenSelector, { tokens: tokens, topUpData: topUpData, setTopUpData: setTopUpData, amount: topUpData.amount }))] }), _jsxs("div", { className: s.receive, children: [_jsxs("div", { className: s.receiveTitle, children: [t('top-up.step-2.recieve'), ":"] }), _jsxs("div", { className: s.receiveAmount, children: ["$", topUpData.amount.replace(',', '.')] })] })] }));
};
