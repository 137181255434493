// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.VrCfBY0G85ZiLSaTqPw0{margin:30px 0}.tn2H_oZCIas9c1idJMG4{font-size:14px;font-weight:410;color:var(--tg-theme-hint-color);margin-bottom:18px;padding-left:16px}.sU1CYkfOnC89_o_xFlCC{border:2px dashed var(--tg-theme-button-color);padding:20px;min-height:175px;width:100%;display:flex;align-items:center;justify-content:center;flex-direction:column;border-radius:12px;cursor:pointer;transition:all .3s ease 0s}.sU1CYkfOnC89_o_xFlCC:hover{opacity:.9}.nNdVuwahLOw1S0szADL9{margin-top:12px;font-size:20px;color:var(--tg-theme-text-color)}`, "",{"version":3,"sources":["webpack://./src/pages/home/esims/esims.module.scss"],"names":[],"mappings":"AAAA,sBACE,aAAA,CAGF,sBACE,cAAA,CACA,eAAA,CACA,gCAAA,CACA,kBAAA,CACA,iBAAA,CAGF,sBACE,8CAAA,CACA,YAAA,CACA,gBAAA,CACA,UAAA,CACA,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,qBAAA,CACA,kBAAA,CACA,cAAA,CACA,0BAAA,CACA,4BAEE,UAAA,CAIJ,sBACE,eAAA,CACA,cAAA,CACA,gCAAA","sourcesContent":[".inner {\n  margin: 30px 0;\n}\n\n.title {\n  font-size: 14px;\n  font-weight: 410;\n  color: var(--tg-theme-hint-color); \n  margin-bottom: 18px;\n  padding-left: 16px;\n}\n\n.create {\n  border: 2px dashed var(--tg-theme-button-color);\n  padding: 20px;\n  min-height: 175px;\n  width: 100%;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  flex-direction: column; \n  border-radius: 12px;\n  cursor: pointer;\n  transition: all 0.3s ease 0s;\n  &:hover {\n    // transform: scale(0.996);\n    opacity: 0.9;\n  }\n}\n\n.createText {\n  margin-top: 12px;\n  font-size: 20px;\n  color: var(--tg-theme-text-color); \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"inner": `VrCfBY0G85ZiLSaTqPw0`,
	"title": `tn2H_oZCIas9c1idJMG4`,
	"create": `sU1CYkfOnC89_o_xFlCC`,
	"createText": `nNdVuwahLOw1S0szADL9`
};
export default ___CSS_LOADER_EXPORT___;
