import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Title } from '@delab-team/de-ui';
import { useTranslation } from 'react-i18next';
import { TariffEsim } from '../../../components/tariff-esim';
import { SvgSelector } from '../../../assets/svg-selector';
import s from './confirm.module.scss';
export const Confirm = ({ buyData }) => {
    const { t } = useTranslation();
    return (_jsxs(_Fragment, { children: [_jsxs("div", { className: s.check, children: [_jsx(SvgSelector, { id: "info" }), _jsx(Title, { variant: "h2", className: s.checkTitle, children: t('buy-esim.check-tariff') })] }), _jsx(Title, { variant: "h3", className: s.title, children: t('buy-esim.your-tariff') }), _jsx(TariffEsim, { price: buyData.amount, countryCode: buyData.country, internetLimit: buyData.internetLimit, callsLimit: buyData.callsLimit, tariff: buyData.tariff, smsLimit: buyData.smsLimit, id: buyData.id })] }));
};
