import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable max-len */
/* eslint-disable import/no-extraneous-dependencies */
import { useEffect, useState } from 'react';
import WebApp from '@twa-dev/sdk';
import { useTranslation } from 'react-i18next';
import copy from 'copy-to-clipboard';
import { Alert, Title } from '@delab-team/de-ui';
import ReactCountryFlag from 'react-country-flag';
import { InternetDetail } from './internet-detail';
import { SvgSelector } from '../../assets/svg-selector';
import s from './esim-detail.module.scss';
import { useHapticFeedback } from '../../hooks/useHapticFeedback';
export const EsimDetail = ({ setShowDetails, info }) => {
    const [activeTab, setActiveTab] = useState('stats');
    const [isCopiedAddress, setIsCopiedAddress] = useState(null);
    const { t } = useTranslation();
    const TgObj = WebApp;
    const handlePrev = () => {
        TgObj.BackButton.hide();
        TgObj.MainButton.show();
        setShowDetails(false);
        useHapticFeedback();
    };
    useEffect(() => {
        TgObj.BackButton.show();
        TgObj.BackButton.onClick(handlePrev);
        window.scrollTo({ top: 0 });
        return () => {
            TgObj.BackButton.offClick(handlePrev);
        };
    }, []);
    return (_jsxs("div", { className: s.inner, children: [isCopiedAddress && (_jsx(Alert, { type: "important", onClose: () => setIsCopiedAddress(null), icon: _jsx(_Fragment, { children: "\uD83D\uDCC3" }), autoCloseTimeout: 1000, position: "top-right", tgStyles: { background: 'var(--tg-theme-button-color)' }, className: s.alert, children: _jsx("span", { className: s.alertText, children: isCopiedAddress === 'code' ? t('common.address-sm-copied') : t('common.code-activation-copied') }) })), _jsxs("div", { className: s.title, children: [_jsx(ReactCountryFlag, { countryCode: info.countryCode, style: {
                            width: '24px',
                            height: '24px',
                            borderRadius: '50%',
                            objectFit: 'cover'
                        }, svg: true }), _jsx("span", { children: info.rate })] }), info.status === 'GOT_RESOURCE' ? (_jsxs(_Fragment, { children: [_jsxs("div", { className: s.tabs, children: [_jsx("div", { className: `${s.tab} ${activeTab === 'stats' ? s.tabActive : ''}`, onClick: () => {
                                    setActiveTab('stats');
                                    useHapticFeedback();
                                }, children: t('esim.stats') }), _jsx("div", { className: `${s.tab} ${activeTab === 'qr' ? s.tabActive : ''}`, onClick: () => {
                                    setActiveTab('qr');
                                    useHapticFeedback();
                                }, children: t('esim.qr') }), _jsx("div", { className: `${s.tab} ${activeTab === 'instruction' ? s.tabActive : ''}`, onClick: () => {
                                    setActiveTab('instruction');
                                    useHapticFeedback();
                                }, children: t('esim.instruction') })] }), activeTab === 'stats' && (_jsx(InternetDetail, { internetTotal: info.internetLimit, internetUsed: info.internetUsed })), activeTab === 'qr' && (_jsxs("div", { className: s.activate, children: [_jsx("div", { className: s.activateTitle, children: t('esim.activate') }), _jsx("img", { src: info.qrUrl, alt: "qr code" })] })), activeTab === 'instruction' && (_jsxs("div", { className: s.instruction, children: [_jsx(Title, { variant: "h3", className: s.title, children: t('esim.sm-dp-title') }), _jsx("div", { className: s.action, children: _jsx("div", { className: `${s.accountAction} ${s.accountAddress}`, onClick: () => {
                                        copy(info.activationCode.split('$')[1]);
                                        setIsCopiedAddress('code');
                                        useHapticFeedback();
                                    }, children: _jsxs("div", { className: s.address, children: [info.activationCode.split('$')[1], _jsx(SvgSelector, { id: "copy" })] }) }) }), _jsx(Title, { variant: "h3", className: s.title, children: t('esim.activation-code') }), _jsx("div", { className: s.action, children: _jsx("div", { className: `${s.accountAction} ${s.accountAddress}`, onClick: () => {
                                        copy(info.activationCode.split('$')[2]);
                                        setIsCopiedAddress('address');
                                        useHapticFeedback();
                                    }, children: _jsxs("div", { className: s.address, children: [info.activationCode.split('$')[2], _jsx(SvgSelector, { id: "copy" })] }) }) }), _jsxs("div", { className: s.steps, children: [_jsx("div", { className: s.stepsTitle, children: t('esim.steps-title') }), _jsxs("div", { className: s.stepsItem, children: [t('esim.step1'), " ", _jsxs("span", { children: ["\"", t('esim.step1-bold'), "\""] })] }), _jsxs("div", { className: s.stepsItem, children: [t('esim.step2'), " ", _jsxs("span", { children: ["\"", t('esim.step2-bold'), "\""] })] }), _jsxs("div", { className: s.stepsItem, children: [t('esim.step3'), " ", _jsxs("span", { children: ["\"", t('esim.step3-bold'), "\""] })] }), _jsxs("div", { className: s.stepsItem, children: [t('esim.step4'), " ", _jsxs("span", { children: ["\"", t('esim.step4-bold'), "\""] })] }), _jsx("div", { className: s.stepsTitle, children: t('esim.steps-title') }), _jsx("div", { className: s.stepsItem, children: t('esim.not-work-step1') }), _jsx("div", { className: s.stepsItem, children: t('esim.not-work-step2') })] })] }))] })) : (_jsx(InternetDetail, { internetTotal: info.internetLimit, internetUsed: info.internetUsed }))] }));
};
