import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-unused-expressions */
import { useState, useEffect } from 'react';
import { Title } from '@delab-team/de-ui';
import WebAppSDK from '@twa-dev/sdk';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Status } from '../../components/status';
import { Country } from './country';
import { Tariff } from './tariff';
import { Confirm } from './confirm';
import s from './buy-esim.module.scss';
import { SvgSelector } from '../../assets/svg-selector';
import { getPackages, orderEsim } from '../../api/esim';
import { ROUTES } from '../../utils/router';
import { balanceConverter } from '../../utils/balanceConverter';
import { useHapticFeedback } from '../../hooks/useHapticFeedback';
const NEXT_STEP_COLOR = '#40a7e3';
const DISABLED_BUTTON_COLOR = '#78B5F9';
const ERROR_TEXT_COLOR = '#FF0026';
export const BuyEsim = () => {
    const TgObj = WebAppSDK;
    const [activeStep, setActiveStep] = useState(0);
    const [buyData, setBuyData] = useState({
        country: '',
        tariff: '',
        amount: '',
        internetLimit: '',
        callsLimit: '',
        smsLimit: '',
        id: '',
        price: ''
    });
    const [isSuccess, setIsSuccess] = useState(true);
    const [isConfirmed, setIsConfirmed] = useState(false);
    const [availablePackages, setAvailablePackages] = useState([]);
    const [locations, setLocations] = useState([]);
    const [isCountryLoading, setIsCountryLoading] = useState(true);
    const [isBuyLoading, setIsBuyLoading] = useState(false);
    const navigate = useNavigate();
    const { t } = useTranslation();
    const handlePrevStep = () => {
        if (activeStep === 0) {
            navigate('/');
            useHapticFeedback();
        }
        else {
            setActiveStep(prevStep => prevStep - 1);
            useHapticFeedback();
        }
    };
    const handlePay = () => {
        if (isBuyLoading) {
            return;
        }
        setIsBuyLoading(true);
        TgObj.MainButton.showProgress();
        orderEsim(buyData.id, +buyData.price)
            .then((res) => {
            if (res.data.status_code === 402) {
                navigate(ROUTES.TOPUP);
            }
            else {
                setActiveStep(prev => prev + 1);
            }
        })
            .catch((err) => {
            if (err.response?.status === 500) {
                navigate(ROUTES.SOMETHING_WENT_WRONG);
                return;
            }
            navigate(ROUTES.TOPUP);
        })
            .finally(() => {
            TgObj.MainButton.hideProgress();
            setIsBuyLoading(false);
        });
    };
    const handleNextStep = () => {
        if (activeStep === 2) {
            handlePay();
            useHapticFeedback();
            return;
        }
        if (activeStep === 3) {
            !isSuccess ? setIsConfirmed(true) : navigate('/');
            useHapticFeedback();
        }
        else {
            setActiveStep(prevStep => prevStep + 1);
            useHapticFeedback();
        }
    };
    const enableBtn = () => {
        TgObj.MainButton.show();
        TgObj.BackButton.hide();
        if (activeStep === 0) {
            if (!buyData.country) {
                TgObj.MainButton.disable();
                TgObj.MainButton.color = DISABLED_BUTTON_COLOR;
                TgObj.MainButton.text = t('common.next-step');
            }
            else {
                TgObj.MainButton.enable();
                TgObj.MainButton.color = NEXT_STEP_COLOR;
                TgObj.MainButton.text = t('common.next-step');
            }
        }
        else if (activeStep === 1) {
            if (!buyData.tariff.length) {
                TgObj.MainButton.disable();
                TgObj.MainButton.color = DISABLED_BUTTON_COLOR;
                TgObj.MainButton.text = t('common.next-step');
            }
            else {
                TgObj.MainButton.enable();
                TgObj.MainButton.color = NEXT_STEP_COLOR;
                TgObj.MainButton.text = t('common.next-step');
            }
        }
        else if (activeStep === 2) {
            if (!isConfirmed) {
                TgObj.MainButton.enable();
                TgObj.MainButton.color = NEXT_STEP_COLOR;
                TgObj.MainButton.text = t('common.confirm-pay');
            }
        }
        else if (activeStep === 3) {
            if (isSuccess) {
                TgObj.MainButton.enable();
                TgObj.MainButton.color = NEXT_STEP_COLOR;
                TgObj.MainButton.text = t('common.return-to-main');
            }
            else {
                TgObj.MainButton.color = NEXT_STEP_COLOR;
                TgObj.MainButton.text = t('common.try-again');
            }
        }
        else if (activeStep === 4 && isSuccess) {
            if (isSuccess) {
                TgObj.MainButton.enable();
                TgObj.MainButton.color = NEXT_STEP_COLOR;
                TgObj.MainButton.text = t('common.return-to-main');
            }
            else {
                TgObj.MainButton.color = NEXT_STEP_COLOR;
                TgObj.MainButton.text = t('common.try-again');
            }
        }
    };
    const backBtn = () => {
        TgObj.BackButton.show();
    };
    const parsePackagesData = (data) => {
        const countryPricesMap = {};
        // Проход по массиву тарифов для сбора информации по странам
        data.forEach((packageItem) => {
            if (!countryPricesMap[packageItem.location]) {
                countryPricesMap[packageItem.location] = {
                    max: balanceConverter(packageItem.price),
                    min: balanceConverter(packageItem.price)
                };
            }
            else {
                countryPricesMap[packageItem.location].max = Math.max(countryPricesMap[packageItem.location].max, +balanceConverter(packageItem.price));
                countryPricesMap[packageItem.location].min = Math.min(countryPricesMap[packageItem.location].min, +balanceConverter(packageItem.price));
            }
        });
        // Преобразование объекта с информацией по странам в массив
        const countryPrices = Object.keys(countryPricesMap).map(countryCode => ({
            country: countryCode,
            priceRange: countryPricesMap[countryCode],
            // @ts-ignore
            countryFullName: // @ts-ignore
            data.find(item => item.location === countryCode)?.locationNetworkList[0].locationName
        }));
        setLocations(countryPrices);
    };
    useEffect(() => {
        getPackages()
            .then((res) => {
            setAvailablePackages(res.data);
            parsePackagesData(res.data);
        })
            .finally(() => setIsCountryLoading(false));
    }, []);
    useEffect(() => {
        enableBtn();
        backBtn();
        TgObj.MainButton.onClick(handleNextStep);
        TgObj.BackButton.onClick(handlePrevStep);
        return () => {
            TgObj.MainButton.offClick(handleNextStep);
            TgObj.BackButton.offClick(handlePrevStep);
        };
    }, [handleNextStep, activeStep, isConfirmed, isSuccess]);
    const step = activeStep === 0
        ? t('buy-esim.steps.country')
        : activeStep === 1
            ? t('buy-esim.steps.tariff')
            : activeStep === 2
                ? t('buy-esim.steps.payming')
                : activeStep === 3
                    ? t('buy-esim.steps.ready')
                    : t('buy-esim.steps.unknown');
    return (_jsxs("div", { children: [_jsx(Title, { variant: "h2", className: s.title, children: t('buy-esim.title') }), _jsxs("div", { className: s.top, children: [_jsxs("div", { className: s.topInfo, children: [t('common.step'), " ", activeStep + 1, ": ", step] }), _jsxs("div", { className: s.topInner, children: [_jsx("div", { className: `${s.step} ${s.stepActive} ${activeStep >= 1 ? s.stepChecked : ''}`, children: activeStep >= 1 && _jsx(SvgSelector, { id: "check2" }) }), _jsx("div", { className: s.divider, style: { background: activeStep >= 1 ? NEXT_STEP_COLOR : '' } }), _jsx("div", { className: `${s.step} ${activeStep >= 1 ? s.stepActive : ''} ${activeStep >= 2 ? s.stepChecked : ''}`, children: activeStep >= 2 && _jsx(SvgSelector, { id: "check2" }) }), _jsx("div", { className: s.divider, style: { background: activeStep >= 2 ? NEXT_STEP_COLOR : '' } }), _jsx("div", { className: `${s.step} ${activeStep >= 2 ? s.stepActive : ''} ${activeStep >= 3 ? s.stepChecked : ''}`, style: { color: !isSuccess ? ERROR_TEXT_COLOR : '' }, children: activeStep >= 3 && _jsx(SvgSelector, { id: "check2" }) }), _jsx("div", { className: s.divider, style: { background: activeStep >= 3 ? NEXT_STEP_COLOR : '' } }), _jsx("div", { className: `${s.step} ${activeStep >= 3 ? s.stepActive : ''} ${!isSuccess ? s.stepError : ''}`, style: { color: !isSuccess ? ERROR_TEXT_COLOR : '' } })] })] }), activeStep === 0 && (_jsx(Country, { buyData: buyData, setBuyData: setBuyData, locations: locations, isLoading: isCountryLoading })), activeStep === 1 && (_jsx(Tariff, { buyData: buyData, setBuyData: setBuyData, tariffs: availablePackages.filter(packageItem => packageItem.location === buyData.country) })), activeStep === 2 && !isConfirmed ? (_jsx(Confirm, { buyData: buyData })) : (activeStep === 3 && _jsx(Status, { isSuccess: isSuccess }))] }));
};
