import { jsx as _jsx } from "react/jsx-runtime";
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable import/no-unresolved */
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
// import eruda from 'eruda'
import { App } from './App';
import { TonConnectProvider } from './providers/tonConnectProvider';
import 'react-toastify/dist/ReactToastify.css';
import './index.css';
const root = ReactDOM.createRoot(document.getElementById('root'));
const el = document.createElement('div');
document.body.appendChild(el);
// eruda.init({
//     container: el,
//     tool: [ 'console', 'elements' ]
// })
root.render(_jsx(TonConnectProvider, { children: _jsx(BrowserRouter, { children: _jsx(App, {}) }) }));
