import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Text } from '@delab-team/de-ui';
import WebApp from '@twa-dev/sdk';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import ReactCountryFlag from 'react-country-flag';
import { SvgSelector } from '../../assets/svg-selector';
import { capitalizeFirstLetter } from '../../utils/capitalizeFirstLetter';
import { formatBytes } from '../../utils/sizeConvert';
import { endDate } from '../../utils/endDateChecker';
import { useHapticFeedback } from '../../hooks/useHapticFeedback';
import s from './esim.module.scss';
const nonHoveredStyle = {
    scale: 1,
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)'
};
const hoveredStyle = {
    scale: 1,
    boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.2)'
};
export const Esim = ({ rate, internetLimit, internetUsed, status, activationCode, setShowDetails, setEsimInfoDetail, countryCode, qrUrl, activeAt, duration }) => {
    const TgObj = WebApp;
    const { t } = useTranslation();
    return (_jsxs(motion.div, { className: s.esim, onClick: () => {
            setShowDetails(true);
            setEsimInfoDetail({
                rate,
                internetLimit,
                internetUsed,
                status,
                activationCode,
                countryCode,
                qrUrl
            });
            TgObj.MainButton.hide();
            useHapticFeedback();
        }, initial: nonHoveredStyle, whileHover: hoveredStyle, animate: nonHoveredStyle, transition: { duration: 0.3 }, children: [_jsxs("div", { className: s.esimTop, children: [_jsx("div", { className: s.esimTopLeft, children: _jsxs(Text, { className: s.esimRate, children: [_jsx(ReactCountryFlag, { countryCode: countryCode, style: {
                                        width: '24px',
                                        height: '24px',
                                        borderRadius: '50%',
                                        objectFit: 'cover'
                                    }, svg: true }), _jsxs("span", { children: [capitalizeFirstLetter(rate), " "] })] }) }), _jsx("div", { className: s.esimTopRight, children: _jsx(SvgSelector, { id: "chevron-right" }) })] }), _jsxs("div", { className: s.esimBottom, children: [_jsxs("div", { className: s.esimStats, children: [_jsx(SvgSelector, { id: "earth" }), _jsxs("div", { className: s.esimStatsTop, children: [formatBytes(internetUsed), _jsx("span", { children: " / " }), _jsx("span", { children: formatBytes(internetLimit) })] }), _jsx(Text, { className: s.text, children: t('esim.internet') })] }), endDate(activeAt, duration) && (_jsxs("div", { className: s.esimStats, children: [_jsx("div", { className: s.until, children: t('esim.until') }), _jsx("div", { className: s.date, children: endDate(activeAt, duration) })] }))] })] }));
};
