import { jsx as _jsx } from "react/jsx-runtime";
import Lottie from 'react-lottie';
import * as LoadingSticker from './loading.json';
import s from './spinner-screen.module.scss';
export const SpinnerScreen = () => {
    const approveOptions = {
        loop: true,
        autoplay: true,
        animationData: LoadingSticker,
        rendererSettings: { preserveAspectRatio: 'xMidYMid slice' },
    };
    return (_jsx("div", { className: s.inner, children: _jsx(Lottie, { options: approveOptions, height: 200, isClickToPauseDisabled: true, width: 200 }) }));
};
