/* eslint-disable @typescript-eslint/no-shadow */
import WebApp from '@twa-dev/sdk';
import axios from 'axios';
const instance = axios.create({
    baseURL: 'https://whale-app-l9xyz.ondigitalocean.app/'
    // baseURL: "https://cors-anywhere.herokuapp.com/https://tests.sek.su/api/",
});
instance.interceptors.request.use((config) => {
    const token = localStorage.getItem('token');
    // config.headers["telegram-data"] = "query_id=AAEjgEoQAAAAACOAShCxAyVt&user=%7B%22id%22%3A273317923%2C%22first_name%22%3A%227yon%22%2C%22last_name%22%3A%22%22%2C%22username%22%3A%22dev7yon%22%2C%22language_code%22%3A%22ru%22%2C%22allows_write_to_pm%22%3Atrue%7D&auth_date=1708957683&hash=8d3a1d173236780a87fe9947f2e12e6acc9ba9ccee4dbe56acb7612cebc60bba"
    config.headers["telegram-data"] = WebApp.initData;
    return config;
}, error => Promise.reject(error));
export default instance;
