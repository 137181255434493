import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable import/no-extraneous-dependencies */
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import WebAppSDK from '@twa-dev/sdk';
import { Info } from './info';
import { Esims } from './esims';
import { EsimDetail } from '../../components/esim-detail';
import { useHapticFeedback } from '../../hooks/useHapticFeedback';
import { ROUTES } from '../../utils/router';
const NEXT_STEP_COLOR = '#40a7e3';
export const Home = ({ rawAddress, totalBalance, esims }) => {
    const [showDetails, setShowDetails] = useState(false);
    const [esimInfoDetail, setEsimInfoDetail] = useState({});
    const TgObj = WebAppSDK;
    const navigate = useNavigate();
    const { t } = useTranslation();
    const enableBtn = () => {
        TgObj.MainButton.show();
        TgObj.MainButton.enable();
        TgObj.MainButton.color = NEXT_STEP_COLOR;
        TgObj.MainButton.text = t('home-page.create');
    };
    const handleClick = () => {
        TgObj.MainButton.hide();
        navigate(ROUTES.BUY_ESIM);
        useHapticFeedback();
    };
    useEffect(() => {
        TgObj.BackButton.hide();
        enableBtn();
        TgObj.MainButton.onClick(handleClick);
        return () => {
            TgObj.MainButton.offClick(handleClick);
            TgObj.MainButton.hide();
        };
    }, []);
    return (_jsxs(_Fragment, { children: [_jsx(Info, { rawAddress: rawAddress, totalBalance: totalBalance }), !showDetails ? (_jsx(Esims, { setShowDetails: setShowDetails, setEsimInfoDetail: setEsimInfoDetail, esims: esims })) : (_jsx(EsimDetail, { setShowDetails: setShowDetails, info: esimInfoDetail }))] }));
};
