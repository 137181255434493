import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable consistent-return */
/* eslint-disable max-len */
/* eslint-disable no-return-assign */
/* eslint-disable import/no-extraneous-dependencies */
import { useEffect } from 'react';
import Lottie from 'react-lottie';
import WebAppSDK from '@twa-dev/sdk';
import { useNavigate } from 'react-router-dom';
import { t } from 'i18next';
import * as oopsSticker from '../../assets/stickers/oops.json';
import { ROUTES } from '../../utils/router';
import { useHapticFeedback } from '../../hooks/useHapticFeedback';
import s from './something-wrong.module.scss';
export const SomethingWentWrong = ({ isError }) => {
    const navigate = useNavigate();
    const approveOptions = {
        loop: true,
        autoplay: true,
        animationData: oopsSticker,
        rendererSettings: { preserveAspectRatio: 'xMidYMid slice' }
    };
    const handleButton = () => {
        navigate(ROUTES.HOME);
        useHapticFeedback();
        window.location.reload();
    };
    useEffect(() => {
        if (!isError)
            return;
        WebAppSDK.MainButton.show();
        WebAppSDK.MainButton.enable();
        WebAppSDK.MainButton.text = 'Reload page';
        WebAppSDK.MainButton.onClick(handleButton);
        WebAppSDK.BackButton.hide();
        return () => WebAppSDK.MainButton.offClick(handleButton);
    }, [isError]);
    return (_jsxs("div", { className: s.inner, children: [_jsx("div", { className: s.innerImg, children: _jsx(Lottie, { options: approveOptions, height: 200, isClickToPauseDisabled: true, width: 200 }) }), _jsx("h1", { className: `${s.innerTitle} ${s.innerTitleRed}`, children: t('error.oops') }), _jsx("h2", { className: s.innerText, children: t('error.smth-wrong') }), _jsx("h2", { className: s.innerText, children: t('error.support') })] }));
};
