import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
/* eslint-disable no-nested-ternary */
/* eslint-disable import/no-extraneous-dependencies */
import { useState } from 'react';
import { v1 } from 'uuid';
import { useTranslation } from 'react-i18next';
import ReactCountryFlag from 'react-country-flag';
import { Text, Title } from '@delab-team/de-ui';
import { SvgSelector } from '../../../assets/svg-selector';
import { CountrySkeleton } from '../../../components/country-skeleton';
import { useHapticFeedback } from '../../../hooks/useHapticFeedback';
import s from './country.module.scss';
export const Country = ({ buyData, setBuyData, locations, isLoading }) => {
    const { t } = useTranslation();
    const [searchValue, setSearchValue] = useState('');
    return (_jsxs(_Fragment, { children: [_jsx(Title, { variant: "h2", className: s.title, children: t('buy-esim.choose-country') }), isLoading ? (_jsx(CountrySkeleton, {})) : (_jsxs("div", { className: s.inner, children: [_jsx("div", { className: s.inputInner, children: _jsx("input", { className: s.input, value: searchValue, onChange: (e) => {
                                setSearchValue(e.target.value);
                            }, placeholder: t('buy-esim.find-country'), type: "text" }) }), _jsx("ul", { children: locations
                            .filter(item => item.country.split(',').length === 1)
                            .filter(item => item.countryFullName
                            .toLowerCase()
                            .includes(searchValue.toLocaleLowerCase()))
                            .sort((a, b) => {
                            const textA = a.countryFullName;
                            const textB = b.countryFullName;
                            return textA < textB ? -1 : textA > textB ? 1 : 0;
                        })
                            .map(location => (_jsxs("li", { className: `${s.rate} ${buyData.country === location.country
                                ? s.rateActive
                                : s.rateCasual}`, onClick: () => {
                                setBuyData({ ...buyData, country: location.country });
                                useHapticFeedback();
                            }, children: [_jsxs("div", { className: s.rateInfo, children: [_jsx(ReactCountryFlag, { countryCode: location.country, style: {
                                                width: '24px',
                                                height: '24px',
                                                borderRadius: '50%',
                                                objectFit: 'cover'
                                            }, svg: true }), _jsx(Text, { className: s.rateTextCountry, children: location.countryFullName })] }), _jsxs("div", { className: `${s.ratePrice}`, children: [_jsxs("div", { className: s.rateText, children: ["$", (location.priceRange.min / 100).toFixed(2), " - $", (location.priceRange.max / 100).toFixed(2)] }), buyData.country === location.country && (_jsx(SvgSelector, { id: "check3" }))] })] }, v1()))) })] }))] }));
};
