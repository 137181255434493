import { jsx as _jsx } from "react/jsx-runtime";
import { useNavigate } from 'react-router-dom';
import WebAppSDK from '@twa-dev/sdk';
import { FirstStep } from './steps/first-step';
import { ROUTES } from '../../utils/router';
import { useHapticFeedback } from '../../hooks/useHapticFeedback';
import s from './introduction.module.scss';
export const Introduction = ({ setShowIntroduction, isTg, rawAddress, showIntroduction }) => {
    const TgObj = WebAppSDK;
    const navigate = useNavigate();
    const handleIntroductionClose = () => {
        localStorage.setItem('hasPassedIntroduction', 'true');
        localStorage.removeItem('currentIntroductionStep');
        localStorage.removeItem('skippedIntroduction');
        setShowIntroduction(false);
        TgObj.BackButton.hide();
        useHapticFeedback();
        navigate(ROUTES.HOME);
    };
    const handleNextStep = () => {
        localStorage.setItem('hasPassedIntroduction', 'true');
        localStorage.removeItem('currentIntroductionStep');
        localStorage.removeItem('skippedIntroduction');
        setShowIntroduction(false);
        TgObj.BackButton.hide();
        useHapticFeedback();
        navigate(ROUTES.HOME);
    };
    return (_jsx("div", { className: s.wrapper, children: _jsx(FirstStep, { handleNextStep: handleNextStep, handleSkip: handleIntroductionClose, rawAddress: rawAddress }) }));
};
