// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.k7m7yQUXL8xQVGp6DGhE{margin:0 auto;padding:0 16px;overflow:hidden;max-width:425px}`, "",{"version":3,"sources":["webpack://./src/layout/layout.module.scss"],"names":[],"mappings":"AAAA,sBACE,aAAA,CACA,cAAA,CACA,eAAA,CACA,eAAA","sourcesContent":[".wrapper {\n  margin: 0 auto;\n  padding: 0 16px;\n  overflow: hidden;\n  max-width: 425px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `k7m7yQUXL8xQVGp6DGhE`
};
export default ___CSS_LOADER_EXPORT___;
