import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState, useRef, useEffect } from 'react';
import { TonApi } from '@delab-team/ton-api-sdk';
import { motion, AnimatePresence } from 'framer-motion';
import { tokensData as mockTokens } from '../../mocks/tokens';
import { SvgSelector } from '../../assets/svg-selector';
import s from './token-selector.module.scss';
export const TokenSelector = ({ tokens, topUpData, setTopUpData, amount }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedToken, setSelectedToken] = useState(undefined);
    const [tokensData, setTokensData] = useState(undefined);
    const [isLoading, setIsLoading] = useState(true);
    const dropdownRef = useRef(null);
    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setIsOpen(false);
        }
    };
    const handleTokenSelect = (token) => {
        setSelectedToken(token);
        setIsOpen(false);
    };
    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);
    useEffect(() => {
        if (!selectedToken) {
            setSelectedToken(tokensData?.[0]);
            setTopUpData({
                ...topUpData,
                token: tokensData?.[0]?.symbol,
                tokenAddress: tokensData?.[0]?.address,
                transactionAmount: tokensData?.[0]?.amount
            });
        }
    }, [tokensData]);
    const api = new TonApi('AFJOFCU7U3SD7DYAAAAFNHSJF3J4OKQQEXIMHCEQSXUQJAGVP227ZLZ3G3MQTBEU5P3JMRI', 'mainnet');
    useEffect(() => {
        if (!amount) {
            return;
        }
        const fetchTokenPrices = async () => {
            try {
                setIsLoading(true);
                const tokenAddresses = tokens.map((item) => {
                    if (item.symbol === 'TON') {
                        return '0:9a8da514d575d20234c3fb1395ee9138f5f1ad838abc905dc42c2389b46bd015';
                    }
                    return item.address;
                });
                // @ts-ignore
                const res = await api.Rates.getTokenPrice(tokenAddresses, 'usd');
                const tokensDataNew = tokens.map((item) => {
                    const tokenAddress = item.symbol === 'TON'
                        ? '0:9a8da514d575d20234c3fb1395ee9138f5f1ad838abc905dc42c2389b46bd015'
                        : item.address;
                    // @ts-ignore
                    const prices = res?.rates[tokenAddress]?.prices?.USD;
                    let amountInUsd;
                    if (!prices) {
                        const increasedAmount = (Number(amount) / 0.01) * 1.05;
                        amountInUsd = increasedAmount.toFixed(2);
                    }
                    else {
                        const reverseRate = 1 / prices;
                        const increasedAmount = Number(amount) * reverseRate * 1.05;
                        amountInUsd = increasedAmount.toFixed(2);
                    }
                    return {
                        ...item,
                        amount: amountInUsd,
                        tokenLogo: mockTokens.find((tokenFindThing) => tokenFindThing.token === item.symbol)?.tokenLogo
                    };
                });
                setTokensData(tokensDataNew);
                setIsLoading(false);
            }
            catch (error) {
                console.error('Error fetching token prices:', error);
                setIsLoading(false);
            }
            finally {
                setIsLoading(false);
            }
        };
        fetchTokenPrices();
    }, [amount, selectedToken, tokens]);
    return (_jsxs(_Fragment, { children: [selectedToken && (_jsxs(motion.div, { onClick: () => setIsOpen(!isOpen), className: `${s.selectToken} ${s.selectTokenActive}`, children: [_jsxs("div", { className: s.selectTokenLeft, children: [_jsx("img", { src: selectedToken?.tokenLogo, alt: "Token Logo" }), _jsx("span", { children: selectedToken?.symbol })] }), tokensData?.length && (_jsxs(motion.div, { className: `${s.selectTokenRight2} ${isOpen ? s.selectTokenOpen : ''}`, transition: { duration: 0.2 }, children: [_jsx(motion.span, { className: s.selectTokenAmount, children: selectedToken?.amount.replace(',', '.') }), _jsx("div", { className: s.selector, children: _jsx(motion.div, { initial: false, animate: { rotate: isOpen ? 180 : 0 }, transition: { duration: 0.2 }, children: _jsx(SvgSelector, { id: "chevron-bottom2" }) }) })] }))] })), _jsx(AnimatePresence, { children: isOpen && (_jsx(motion.ul, { className: s.selectList, initial: { opacity: 0, height: 0 }, animate: { opacity: 1, height: 'auto' }, exit: { opacity: 0, height: 0 }, children: tokensData.map((token) => (_jsxs(motion.li, { onClick: () => {
                            handleTokenSelect(token);
                            setTopUpData({
                                ...topUpData,
                                token: token.symbol,
                                tokenAddress: token.address,
                                transactionAmount: token.amount
                            });
                        }, className: s.selectToken, children: [_jsxs("div", { className: s.selectTokenLeft, children: [_jsx("img", { src: token?.tokenLogo, alt: "Token Logo" }), _jsx("span", { children: token.symbol })] }), _jsx("div", { className: s.selectTokenRight, children: _jsx("span", { className: s.selectTokenAmount, children: token.amount.replace(',', '.') }) })] }, token.address))) })) })] }));
};
