import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable max-len */
/* eslint-disable import/no-extraneous-dependencies */
import { useEffect } from 'react';
import { Text, Title } from '@delab-team/de-ui';
import Lottie from 'react-lottie';
import { useTranslation } from 'react-i18next';
import { useTonConnectUI } from '@tonconnect/ui-react';
import { Button } from '../../../../components/ui/button';
import * as firstSticker from '../../../../assets/stickers/first.json';
import { SvgSelector } from '../../../../assets/svg-selector';
import { useHapticFeedback } from '../../../../hooks/useHapticFeedback';
import s from './first-step.module.scss';
export const FirstStep = ({ handleNextStep, rawAddress, handleSkip }) => {
    const approveOptions = {
        loop: true,
        autoplay: true,
        animationData: firstSticker,
        rendererSettings: { preserveAspectRatio: 'xMidYMid slice' }
    };
    const [tonConnectUI, setOptions] = useTonConnectUI();
    const { t } = useTranslation();
    // const auth = !!localStorage.getItem('ton-connect-ui_wallet-info')
    useEffect(() => {
        const authStorage = !!localStorage.getItem('ton-connect-ui_wallet-info');
        const auth = tonConnectUI.connected;
        if (!auth || !authStorage) {
            return;
        }
        handleNextStep();
    }, [
        rawAddress,
        tonConnectUI,
        tonConnectUI.connected,
        tonConnectUI.modalState.status,
        tonConnectUI.modalState,
        tonConnectUI.wallet?.account.address
    ]);
    return (_jsx("div", { className: s.firstStep, children: _jsxs("div", { className: s.content, children: [_jsx(Lottie, { options: approveOptions, height: 140, isClickToPauseDisabled: true, width: 140 }), _jsxs(Title, { variant: "h1", className: s.firstTitle, children: [t('introduction.welcome-title'), " ", _jsx("span", { children: "DeSim" })] }), _jsx(Text, { className: s.firstText, children: t('introduction.welcome-description1') }), _jsx(Text, { className: s.firstText, children: t('introduction.welcome-description2') }), _jsxs(Button, { className: s.firstButton, onClick: () => {
                        tonConnectUI.connectWallet();
                        useHapticFeedback();
                    }, children: [_jsx(SvgSelector, { id: "apps" }), t('common.connect-btn')] }), _jsx(Button, { className: s.skipButton, onClick: () => {
                        handleSkip();
                        useHapticFeedback();
                    }, children: t('common.skip') })] }) }));
};
