import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { v1 } from 'uuid';
import { useTranslation } from 'react-i18next';
import { Title } from '@delab-team/de-ui';
import { TariffEsim } from '../../../components/tariff-esim';
import { formatBytes } from '../../../utils/sizeConvert';
import s from './tariff.module.scss';
export const Tariff = ({ buyData, setBuyData, tariffs }) => {
    const { t } = useTranslation();
    return (_jsxs(_Fragment, { children: [_jsx(Title, { variant: "h2", className: s.title, children: t('buy-esim.choose-tariff') }), tariffs
                .sort((a, b) => a.price - b.price)
                .map(tariff => (_jsx(TariffEsim, { buyData: buyData, countryCode: buyData.country, setBuyData: setBuyData, tariffChanges: true, callsLimit: "12", internetLimit: formatBytes(tariff.volume), price: tariff.price.toString(), smsLimit: "20", tariff: tariff.name, id: tariff.packageCode }, v1())))] }));
};
