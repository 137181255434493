import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Text } from '@delab-team/de-ui';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import ReactCountryFlag from 'react-country-flag';
import { SvgSelector } from '../../assets/svg-selector';
import { capitalizeFirstLetter } from '../../utils/capitalizeFirstLetter';
import s from './tariff-esim.module.scss';
import { useHapticFeedback } from '../../hooks/useHapticFeedback';
const nonHoveredStyle = {
    scale: 1,
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)'
};
const hoveredStyle = {
    scale: 1,
    boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.2)'
};
export const TariffEsim = ({ price, internetLimit, callsLimit, smsLimit, tariff, buyData, setBuyData, countryCode, tariffChanges = false, id }) => {
    const { t } = useTranslation();
    return (_jsxs(motion.div, { className: `${s.tariff} ${buyData?.tariff === tariff ? s.tariffActive : ''} ${!tariffChanges ? s.tariffBorder : ''}`, onClick: () => {
            tariffChanges
                && setBuyData
                && buyData
                && setBuyData({
                    ...buyData,
                    tariff,
                    amount: price,
                    callsLimit,
                    smsLimit,
                    price,
                    internetLimit,
                    id
                });
            useHapticFeedback();
        }, initial: nonHoveredStyle, whileHover: hoveredStyle, animate: buyData?.tariff === tariff ? hoveredStyle : nonHoveredStyle, transition: { duration: 0.3 }, children: [_jsxs("div", { className: s.tariffTop, children: [_jsxs("div", { className: s.tariffTariff, children: [buyData?.tariff === tariff ? _jsx(SvgSelector, { id: "check3" }) : null, _jsx(ReactCountryFlag, { countryCode: countryCode, style: {
                                    width: '24px',
                                    height: '24px',
                                    borderRadius: '50%',
                                    objectFit: 'cover'
                                }, svg: true }), _jsx("span", { children: capitalizeFirstLetter(tariff.substr(tariff.indexOf(' ') + 1)) })] }), _jsxs(Text, { className: s.tariffText, children: ["$", (+price / 10000).toFixed(2)] })] }), _jsx("div", { className: s.tariffBottom, children: _jsxs("div", { className: s.tariffStats, children: [_jsx(SvgSelector, { id: "earth" }), _jsx("div", { className: s.tariffStatsTop, children: internetLimit }), _jsx(Text, { className: s.tariffTextTariff, children: t('esim.internet') })] }) })] }));
};
