import axios from '../utils/axios';
export const getOwnedEsims = async (limit = 10, skip = 0) => axios.get('esim/mine', {
    params: {
        limit,
        skip
    }
});
export const getPackages = async () => axios.get('tariffs/getAllTariffs');
export const orderEsim = async (packageCode, price) => axios.post('tariffs/orderTariff', {
    packageCode,
    price,
    count: 1
});
export const cancelEsim = async (esimId) => axios.post('esim/cancel', { esim_id: esimId });
