// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AEuh6mqOtAnbuZ9nFxWh{display:flex;align-items:center;justify-content:center;flex-direction:column;margin:0 0 30px 0}.AEuh6mqOtAnbuZ9nFxWh:not(:last-child){margin:36px auto 30px auto}.yGPX2aN7_mU_XfFNJWKa{font-size:17px;font-weight:540;margin-bottom:2px;color:var(--tg-theme-text-color)}.BcGl0sw6Hl_pqt0ZM8Wo{font-size:14px;color:var(--tg-theme-hint-color)}.FCmfNN7fsmk731xlmCeC{display:flex;align-items:center;justify-content:center}.FCmfNN7fsmk731xlmCeC svg{cursor:pointer}`, "",{"version":3,"sources":["webpack://./src/components/esim-detail/internet-detail/internet-detail.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,qBAAA,CACA,iBAAA,CACA,uCACE,0BAAA,CAEF,sBACE,cAAA,CACA,eAAA,CACA,iBAAA,CACA,gCAAA,CAEF,sBACE,cAAA,CACA,gCAAA,CAIJ,sBACE,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,0BACE,cAAA","sourcesContent":[".info {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  flex-direction: column;\n  margin: 0 0 30px 0;\n  &:not(:last-child) {\n    margin: 36px auto 30px auto;\n  }\n  &Title {\n    font-size: 17px;\n    font-weight: 540;\n    margin-bottom: 2px;\n    color: var(--tg-theme-text-color);\n  }\n  &Used {\n    font-size: 14px;\n    color: var(--tg-theme-hint-color);\n  }\n}\n\n.chart {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  svg {\n    cursor: pointer;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"info": `AEuh6mqOtAnbuZ9nFxWh`,
	"infoTitle": `yGPX2aN7_mU_XfFNJWKa`,
	"infoUsed": `BcGl0sw6Hl_pqt0ZM8Wo`,
	"chart": `FCmfNN7fsmk731xlmCeC`
};
export default ___CSS_LOADER_EXPORT___;
